import React from 'react';
import '../App.css';
import casting from '../assets/Casting.jpg'

class AboutUs extends React.Component {

    render() {
        return (
            <div className='w3-container w3-margin w3-padding' id='aboutUs'>
                <div className='w3-cell-row w3-mobile'>
                    <div className='w3-cell' style={{ display: 'flex', justifyContent: 'center' }}>
                        <img className='w3-card w3-round' src={casting} alt='Casting Concrete'></img>
                    </div>
                    <div className='w3-cell w3-cell-middle'>
                        <h1 className='w3-center w3-section' tabIndex={0} > About Us </h1>
                        <p className='w3-margin w3-section w3-margin-left'>
                            Coreslab Structures (CONN) Inc. manufactures precast concrete materials for all building sectors
                            such as schools, hospitals, office buildings, multi-story apartments, manufacturing facilities,
                            energy and water treatment plants, bridges, and infrastructure projects. Premanufacturing of
                            architectural cladding as well as structural components improves onsite quality, safety, and
                            building schedules.
                        </p>
                    </div>
                </div>
            </div>

        );
    }
}

export default AboutUs