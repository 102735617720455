import React from 'react';
import '../App.css';
import benefitsImg from '../assets/Benefits.jpg'

class Benefits extends React.Component {

    render() {
        return (
            <div className='w3-container w3-margin w3-padding' id='benefits'>
                <div className='w3-cell-row'>
                <div className='w3-cell w3-cell-middle w3-mobile w3-half'>
                        <h1 className='w3-center w3-section'> Benefits </h1>
                        <div className='w3-margin w3-section'>
                        <ul className='w3-margin w3-section'>
                            <li>401k</li>
                            <li>Profit sharing</li>
                            <li>Medical, dental, vision</li>
                            <li>Basic life insurance</li>
                            <li>Paid time off and holiday pay</li>
                            <li>Short-term disability</li>
                            <li>Additional voluntary benefits: </li>
                            <ul>
                                <li>Additional life insurance</li>
                                <li>Long-term disability </li>
                                <li>Aflac</li>
                            </ul>
                        </ul>
                        </div>
                    </div>
                    <div className='w3-cell w3-cell-middle w3-half w3-mobile' style={{display: 'flex', justifyContent: 'center'}}>
                        <img className='w3-card w3-round' src={benefitsImg} alt='Estimators Working'></img>
                    </div>
                </div>
            </div>

        );
    }
}

export default Benefits