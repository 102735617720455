import React from 'react';
import '../App.css';

class QuoteBlock extends React.Component {

    render() {
        return (
            <div className='w3-container w3-margin w3-padding' id='aboutUs'>
                <div className='w3-cell-row'>
                    <div className='w3-cell w3-cell-middle'>
                        <div className="w3-panel">
                            <span style={{fontSize: '150px', lineHeight: '0.6em', opacity: '0.2'}}> &#10077; </span>
                            <p className="w3-xlarge" style={{marginTop:'-40px'}}>
                                <i> "Coreslab Structures' Connecticut Facility was added to the portfolio of nationally
                                    recognized PCI Certified Plants in 1997. We are a manufacturer of high-performance
                                    precast concrete products serving the building environment with creating sustainable
                                    communities and relationships. With an emphasis on a business culture of employee engagement,
                                    safety and producing high quality materials, our motto "Building a Concrete Future" represents
                                    not only the materials used but also the people and communities we represent."
                                </i></p>
                            <p>Robert Del Vento Jr., V.P. / General Manager</p>
                        </div>

                        <h4 className='w3-margin w3-section w3-center'>

                        </h4>
                    </div>
                </div>
            </div>

        );
    }
}

export default QuoteBlock