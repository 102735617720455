import React from 'react';
import '../App.css';

class ContactUs extends React.Component {

    render() {
        return (
            <div className='w3-container w3-margin' id='contactUs'>
                <h1 className='w3-center w3-section' tabIndex={0} >Contact Us</h1>
                <div className='w3-cell-row'>
                    <div className='w3-cell w3-mobile w3-center w3-xlarge' style={{width: '33.3%'}}>
                        <i className='fa fa-phone'></i>
                        <p>(860) 283-8281</p>
                    </div>
                    <div className='w3-cell w3-mobile w3-center w3-xlarge' style={{width: '33.3%'}}>
                        <i className='fa fa-map-marker'></i>
                        <p>1023 Waterbury Rd, <br /> Thomaston, CT 06787</p>
                    </div>
                    <div className='w3-cell w3-mobile w3-center w3-xlarge' style={{width: '33.3%'}}>
                        <i className='fa fa-envelope'></i>
                        <p>salesconnecticut@coreslab.com</p>
                    </div>
                </div>
            </div>

        );
    }
}

export default ContactUs