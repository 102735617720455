import '../App.css'
import JobMatrix from '../assets/CurrentPositions.json';
import logo from '../assets/Coreslablogo.png';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';

function ApplicationForm(props) {

  const jobData = useLocation();
  let currentRole;
  const navigator = useNavigate();
  let age, authorization, drugTest, transportation, coreslab, construction, coverLetter = 'none';
  let attachment;

  /**
   * Import in Job Data for role if routing directly to position URL rather than from homepage
   */
  if (jobData.state != null) {
    currentRole = jobData.state;
  } else {
    for (let i = 0; i < JobMatrix.length; i++) {
      if (('/Application/' + JobMatrix[i].Role.replaceAll(' ', '')) === jobData.pathname) {
        currentRole = JobMatrix[i];
      }
    }
  }

  const disableToggle = (toggle, impact) => {
    if (document.querySelector(toggle).checked) {
      document.getElementById(impact).removeAttribute('disabled')
    } else {
      document.getElementById(impact).setAttribute('disabled', true)
    }
  }

  const uploadFile = (event) => {
    if(event.target.files[0].size > 51200){
      alert("This file is too large. Please select a file under 50kb");
      document.getElementById('resume').value = "";
    } else {
      attachment = event.target.files[0]
    }
  };

  /**
   * Submits form and sends it through emailJS
   * @param {*} event 
   */
  const submitApplication = (event) => {
    event.preventDefault();

    emailjs.sendForm("application_Service", "application_Form", document.getElementById('applicationForm'), 'JTKal8WPvxIbjREjg').then(
      () => navigator('/Application/Success'),
      error => console.log(error.text)
    );

  }

  let splitIntoBullets = (Id, toSplit) => {
    let bulletList = document.getElementById(Id)
    let keyResponsibilities = toSplit
    keyResponsibilities = keyResponsibilities.split('*')
   
    keyResponsibilities.forEach(Res => {
      let listItem = document.createElement('li')
      listItem.innerHTML = Res
      if(listItem.innerHTML !== ''){
        bulletList.appendChild(listItem)
      }
    })
  }

  useEffect(() => {
    if(document.getElementById('keyResponsibilities').childElementCount === 0){
      splitIntoBullets('keyResponsibilities', currentRole.Key_Responsibilities);
    }
    if(document.getElementById('qualifications').childElementCount === 0){
      splitIntoBullets('qualifications', currentRole.Qualifications);
    }
  })


  return (
    <div className="ApplicationForm" onLoad={() => { window.scrollTo(0, 0); }}>

      <div>
        <button className='w3-round-large w3-margin' onClick={() => navigator('/')}> Back to Homepage </button>
      </div>
      <br /> <br />

      <div className='w3-display-container w3-padding-16 w3-margin'>
        <img alt="Coreslab logo" className='w3-display-middle' src={logo}></img>
      </div>
      <br />

      <div className=' w3-padding-16 w3-margin' style={{ paddingLeft: '15px' }}>
        <h1 className='w3-padding-16' tabIndex={0}> Application For {currentRole.Role}</h1>
        <div className='w3-margin'>
          <p><b>Department:</b> {currentRole.Department}</p>
          <p><b>Position Type:</b> {currentRole['Position']} </p>
          <p><b>Experience Needed:</b> {currentRole['Experience needed']}</p>
          <p><b>Date Posted:</b> {currentRole['Date Posted']}</p>
          <p>{currentRole.Description}</p>
          <p><b>Key responsibilities:</b></p>
          <ul id='keyResponsibilities'></ul>
          <p><b>Qualifications:</b></p>
          <ul id='qualifications'></ul>
        </div>
      </div>


      <form className='w3-container w3-padding w3-margin' onSubmit={submitApplication} id='applicationForm'>

        <input style={{display: 'none'}} name='job_title' defaultValue={currentRole.Role}></input>

        <h1 className='w3-padding-16'> General Information </h1>
        <p><b>Please Note:</b> All below fields are mandatory for application submission unless otherwise marked.</p>

        <div className="w3-row-padding">
          <div className='w3-half'>
            <label className="w3-text-blue"><b>First Name</b></label>
            <input className="w3-input w3-border" id="firstName" type="text" name='first_name' required  />
          </div>
          <div className='w3-half'>
            <label className="w3-text-blue"><b>Last Name</b></label>
            <input className="w3-input w3-border" id="lastName" type="text" name='last_name' required  />
          </div>
        </div>

        <div className="w3-row-padding">
          <div className='w3-half'>
            <label className="w3-text-blue"><b>Email Address</b></label>
            <input className="w3-input w3-border" id="email" name='email' type="text" required  />
          </div>
          <div className='w3-half'>
            <label className="w3-text-blue"><b>Cell Phone</b></label>
            <input className="w3-input w3-border" id='phone' type="text" name='phone' placeholder='(xxx)-xxx-xxx' required />
          </div>
        </div>

        <div className='w3-row-padding' style={{ paddingLeft: '17px', paddingRight: '17px' }}>
          <label className="w3-text-blue"><b>Street Address</b></label>
          <input className="w3-input w3-border" id='address' type="text" name='street' required  />
        </div>

        <div className="w3-row-padding">
          <div className='w3-third'>
            <label className="w3-text-blue"><b>City</b></label>
            <input className="w3-input w3-border" id='city' type="text" name='city' required />
          </div>

          <div className='w3-third'>
            <label className="w3-text-blue"><b>State or Providence</b></label>
            <input className="w3-input w3-border" id='state' name='state' required />
          </div>

          <div className='w3-third'>
            <label className="w3-text-blue"><b>Country</b></label>
            <input className="w3-input w3-border" id='country' name='country' required  />
          </div>
        </div>
        <br />
        <div className='w3-row-padding'>
          <div className='w3-third' required>
            <p className="w3-text-blue"><b>What languages are you fluent in?</b></p>
            <input type="checkbox" name="languages_en" value="English" className="english" />
            <label style={{ paddingLeft: '10px' }}> English</label> <br />
            <input type="checkbox" name="language_es" value="Spanish" className="spanish" />
            <label style={{ paddingLeft: '10px' }}> Spanish</label> <br />
            <input type="checkbox" name="language" value="Other" className="otherLang" onChange={() => { disableToggle('.otherLang', 'otherLanguages') }} />
            <label style={{ paddingLeft: '10px' }}> Other: </label>
            <input type="text" id="otherLanguages" name="languages_other" disabled className="w3-input w3-border"></input>
          </div>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Are you 18 years or older?</b></p>
            <input className="w3-radio age" type="radio" value="Yes" name="age" onChange={() => { age = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio age" type="radio" value="No" name="age" required onChange={() => { age = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
        </div>

        <h1 className='w3-padding-16'> Position Relevant Information </h1>

        <div className='w3-row-padding'>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Are you authorized to work in the US?</b></p>
            <input className="w3-radio authorized" type="radio" value="Yes" name="authorization" onChange={() => { authorization = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio authorized" type="radio" value="No" name="authorization" required onChange={() => { authorization = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Are you willing to submit to a drug test?</b></p>
            <input className="w3-radio age" type="radio" value="Yes" name="drugTest" onChange={() => { drugTest = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio age" type="radio" value="No" name="drugTest" required onChange={() => { drugTest = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Do you have reliable transportation to work? *</b></p>
            <input className="w3-radio age" type="radio" value="Yes" name="transportation" onChange={() => { transportation = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio age" type="radio" value="No" name="transportation" required onChange={() => { transportation = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
        </div>
        <br />
        <div className='w3-row-padding'>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Have you previously worked at Coreslab?</b></p>
            <input className="w3-radio authorized" type="radio" value="Yes" name="coreslab" onChange={() => { coreslab = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio authorized" type="radio" value="No" name="coreslab" required onChange={() => { coreslab = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
          <div className='w3-third'>
            <p className='w3-text-blue'><b>Have you previously worked in Construction?</b></p>
            <input className="w3-radio authorized" type="radio" value="Yes" name="construction" onChange={() => { construction = "Yes" }} />
            <label style={{ paddingLeft: '10px' }}>Yes</label> <br />
            <input className="w3-radio authorized" type="radio" value="No" name="construction" required onChange={() => { construction = "No" }} />
            <label style={{ paddingLeft: '10px' }}>No</label>
          </div>
        </div>
        <br />
        <div className='w3-row-padding'>
          <div className='w3-third'>
            <label className="w3-text-blue"><b>Resume (optional) </b></label>
            <input className="w3-input w3-border" name="resumeFile" type="file" id='resume' accept='.doc,.docx,.pdf' equired onChange={uploadFile}/>
          </div>
        </div>

        <div className='w3-row-padding'>
          <div className='w3-twothird'>
            <label className="w3-text-blue"><b>Cover Letter (optional) </b></label>
            <textarea id='coverLetter' className="w3-input w3-border" name='coverLetter' style={{ height: '25vh' }} type="text" placeholder='Copy Your Cover Letter Here'/>
          </div>
        </div>
        <br />

        <div className='w3-row-padding'>
          <button className="w3-right w3-round-large w3-margin" >Submit Application</button>
        </div>

      </form>
    </div>
  );
}

export default ApplicationForm;
