import '../App.css';
import logo from '../assets/Coreslablogo.png'
import Carousel from './Carousel';
import AboutUs from './AboutUs';
import Positions from './Positions';
import ContactUs from './ContactUs';
import Benefits from './Benefits';
import QuoteBlock from './QuoteBlock';
import React from 'react';

/**
 * 
 * Styling left to do:
 * Add icon to website tab
 * hrconnecticut@coreslab.com
 * 
 * Key responsibilities
 * Qualifications
 * 
 * 
 * 
 */

/**
 * Limitations notes:
 * Images in Carousel cannot have spaces in name
 */


function Homepage () {

    return (
      <div className="App">
        <div className="Site-header w3-bar" id='siteHeader'>
        <img src={logo} className="w3-bar-item w3-img"></img>
        <button className="w3-button w3-bar-item w3-right Default-button w3-hover-white w3-margin menuItem" onClick={() => {document.getElementById("aboutUs").scrollIntoView({behavior: "smooth"})}}> About Us</button>
        <button className="w3-button w3-bar-item w3-right Default-button w3-hover-white w3-margin menuItem" onClick={() => {document.getElementById("benefits").scrollIntoView({behavior: "smooth"})}}> Benefits</button>
        <button className="w3-button w3-bar-item w3-right Default-button w3-hover-white w3-margin menuItem" onClick={() => {document.getElementById("positions").scrollIntoView({behavior: "smooth"})}}> Opportunities </button>
        <button className="w3-button w3-bar-item w3-right Default-button w3-hover-white w3-margin menuItem" onClick={() => {document.getElementById("contactUs").scrollIntoView({behavior: "smooth"})}}> Contact Us</button>
        </div>
        <div className="Home">
            <Carousel />
            <AboutUs />
            <QuoteBlock />
            <Benefits />
            <Positions />
            <ContactUs />
        </div>
      </div>
    );

}

export default Homepage;
