import './App.css';
import React from 'react';
import ApplicationForm from './Components/ApplicationForm';
import SuccessPage from './Components/SuccessPage';
import Homepage from './Components/Homepage';
import { HashRouter, Routes, Route } from "react-router-dom";

function App () {

    return (
      <HashRouter>
      <Routes>
        <Route path="/" element={<Homepage />}/>
        <Route path="/Application/:handle" element={<ApplicationForm />} />
        <Route path="/Application/Success" element={<SuccessPage/>} />
      </Routes>
    </HashRouter>
    );

}

export default App;
