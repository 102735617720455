import React from 'react';
import '../App.css';
import JobMatrix from '../assets/CurrentPositions.json';
import { useNavigate } from 'react-router-dom';


function Positions() {

    const navigator = useNavigate();
    const jobs = JobMatrix;
    
    return (
        <div className='w3-container w3-margin' id='positions'>
            <h1 tabIndex={0}> Current Opportunities </h1>
            <div id="jobsList">
                {
                    jobs.map((value, index) => {
                        return <div className='w3-card w3-row w3-margin w3-padding-large' key={index} >
                            <h4 className='w3-third' tabIndex={0}> {value.Role} </h4>
                            <p className='w3-quarter'> {value.Department} </p>
                            <p className='w3-quarter'> {value.Position} </p>
                            <button className='w3-cell w3-round-large w3-right w3-rest' onClick={() => navigator(('/Application/' + value.Role.replaceAll(' ', '')), {state: value} )}>Apply Now</button>
                        </div>
                    })
                }
            </div>
        </div>
    );


}

export default Positions