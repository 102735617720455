import '../App.css';
import React, { useEffect } from 'react';



function Carousel() {

  let altText;
  let activeSlide = 0;
  const importAllImgs = (folder) => {
    let images = {};
    folder.keys().forEach((item, index) => {
      images[item.replace('./', '')] = folder(item);
    })
    altText = Object.keys(images);
    return Object.values(images)
  }

  const imageArray = importAllImgs(require.context('../assets/HeaderCarousel', false, /\.(png|jpe?g|svg)$/));

  const nextSlide = () => {
    let allSlides = Object.values(document.getElementsByClassName("activeSlide"))

    allSlides.forEach((val) => {
      val.style.display = "none";
    })
    if (activeSlide < (allSlides.length - 1)) {
      activeSlide++
    } else {
      activeSlide = 0
    }
    
    allSlides[activeSlide].style.display = "block";
  }

  const prevSlide = () => {
    let allSlides = Object.values(document.getElementsByClassName("activeSlide"))

    allSlides.forEach((val) => {
      val.style.display = "none";
    })
    if (activeSlide != 0) {
      activeSlide--
    } else {
      activeSlide = (allSlides.length - 1)
    }
    
    allSlides[activeSlide].style.display = "block";
  }

  useEffect(() => {
    nextSlide();
    const interval = setInterval(() => {
      nextSlide();
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className='w3-display-container carousel' id='carousel'>
      <button className='w3-display-right fa fa-chevron-right' onClick={nextSlide}></button>
      <button className='w3-display-left fa fa-chevron-left' onClick={prevSlide}></button>
      {
        imageArray.map((value, index) => {
          return (
            <div style={{ display: 'none', backgroundImage: `url(${value})` }} key={index} alt={altText[index]} className='activeSlide w3-animate-opacity'></div>
          )
        })
      }

    </div>
  );

}

export default Carousel