import '../App.css';
import logo from '../assets/Coreslablogo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';


function SuccessPage() {

    const navigator = useNavigate();

    return (
        <div className="w3-container w3-center">
            <br/>
            <br/>
            <br/>
            <img src={logo} className="w3-bar-item w3-img"></img>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1>Your application form has successfully been submitted!</h1>
            <div>
                <button className='w3-round-large w3-margin' onClick={() => navigator('/')}> Back to Homepage </button>
            </div>
        </div>
    );

}

export default SuccessPage;
